<template>
  <section class="login-wrapper">
    <div class="login-content">
      <header class="login-header">欢迎登陆一木兴后台管理系统</header>
      <el-form
        :model="userForm"
        status-icon
        :rules="rules"
        ref="userForm"
        class="login-form"
        label-position="left"
      >
        <el-form-item prop="username">
          <el-input
            v-model="userForm.username"
            autocomplete="off"
            placeholder="请输入用户名"
          ></el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input
            type="password"
            v-model="userForm.password"
            autocomplete="off"
            placeholder="请输入密码"
            @keyup.enter.native="submitForm('userForm')"
          ></el-input>
        </el-form-item>
        <el-button
          type="primary"
          @click="submitForm('userForm')"
          class="login-btn"
          >登录</el-button
        >
      </el-form>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    var validateUsrname = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入用户名"));
      } else {
        callback();
      }
    };
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else {
        callback();
      }
    };
    return {
      userForm: {
        username: "",
        password: ""
      },
      rules: {
        username: [{ validator: validateUsrname, trigger: "blur" }],
        password: [{ validator: validatePass, trigger: "blur" }]
      }
    };
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.$axios({
            url: "/back/login",
            method: "post",
            data: this.userForm
          }).then(res => {
            if (res.data.code == 200) {
              if (res.data.data.permission.length !== 0) {
                this.$store.commit("setRole", res.data.data);
                this.$message({
                  type: "success",
                  message: "登录成功"
                });
                this.$router.push({ name: "commodity" });
              } else {
                this.$message({
                  type: "error",
                  message: "无权限，请联系管理员获取权限"
                });
              }
            } else {
              this.$message({
                type: "error",
                message: res.data.msg
              });
            }
          });
        } else {
          return false;
        }
      });
    }
  }
};
</script>

<style lang="less">
.login-wrapper {
  height: calc(100vh - 120px);
  background-color: #f1f2f7;
  position: relative;

  .login-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #ffffff;
    min-width: 300px;
    border-radius: 3px;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    padding: 0 34px;

    .login-header {
      font-weight: 500;
      font-size: 1.3rem;
      margin: 20px auto;
    }

    .login-btn {
      width: 100%;
      font-size: 1rem;
      margin-bottom: 25px;
    }
  }
}
</style>
